
import TablePagination from '@/components/table/TablePagination.vue';
import { BotColumn, BotColumnData } from '@/types/logdata.types';
import { UnassignedBot } from '@/types/om27.types';
import { getDateRenderer } from '@/utils/datetime';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    TablePagination,
  }
})
export default class Om27UnassignedBotsGrid extends Vue {
  @Prop({ required: true, type: Array }) bots!: UnassignedBot[];
  @Prop({ required: true, type: String }) timeZone!: string;
  @Prop({ type: Boolean }) borderAround!: boolean;
  @Prop({ type: Boolean }) dense!: boolean;

  page = 1;
  perPage = 25;

  get columns(): BotColumn<UnassignedBot>[] {
    return [{
      field: 'botName',
      key: 'botName',
      align: 'left',
      width: 300,
      title: 'Task Name',
      renderBodyCell: ({ row }, h) => {
        return <span style="word-break: break-all;">{row.botName}</span>;
      },
    }, {
      field: 'hostShort',
      key: 'hostShort',
      align: 'left',
      width: 150,
      title: 'Server',
    }, {
      field: 'botRunnerId',
      key: 'botRunnerId',
      align: 'left',
      width: 150,
      title: 'Runner',
    }, {
      field: 'lastRunDate',
      key: 'lastRunDate',
      align: 'left',
      width: 200,
      title: 'Last Time Run',
      renderBodyCell: this.getDateRenderer('lastRunDate'),
    }, {
      field: 'paths',
      key: 'paths',
      align: 'left',
      title: 'Bot Path',
      renderBodyCell: ({ row }: { row: UnassignedBot }, h) => {
        return row.paths.map((path: string) => {
          return <div style="word-break: break-all;">...{path}</div>;
        });
      },
    }];
  }

  get rows() {
    let start = (this.page - 1) * this.perPage;
    if (start > this.bots.length) {
      this.page = Math.ceil(this.bots.length / this.perPage);
      start = (this.page - 1) * this.perPage;
    }
    const end = start + this.perPage;
    return this.bots.slice(start, end);
  }

  getDateRenderer(
    field: keyof UnassignedBot,
    infoField: keyof UnassignedBot | '' = ''
  ): BotColumnData<UnassignedBot>['renderBodyCell'] {
    return getDateRenderer(field, this.timeZone, infoField);
  }
}
